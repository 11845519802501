div.customSelect.ant-select.ant-select-single.ant-select-show-arrow
  > div.ant-select-selector {
  border-radius: 20px;
  background-color: #f5f5f5;
  border: unset;
  font-weight: bold;
}

span.customInput > input {
  background-color: #f5f5f5;
  font-weight: bold;
}

.fix-widget-badge {
  position: fixed;
  right: 25px;
  bottom: 57px;
  z-index: 101;
}

.fix-widget {
  position: fixed;
  right: 30px;
  bottom: 20px;
  z-index: 100;
  color: #000;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
