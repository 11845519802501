.site-card-wrapper {
  /* margin-top: 200; */
  /* padding: 24px; */
  /* padding: 16px; */
  /* padding: 16px 16px 8px 16px; */
  padding: 16px 16px 0px 16px;
  /* background-color: green; */
  height: 100%;
}

.card-shadow {
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.12);
}

div.ant-row {
  margin: 0;
  /* background-color: yellow; */
  height: 100%;
}

div.ant-card-body {
  padding: 12px;
}
