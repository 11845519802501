.layout {
  height: 100vh;
  background-color: #fff;
}

.fix-widget-back {
  position: fixed;
  left: 20px;
  top: 17px;
  z-index: 100;
  color: black;
}
