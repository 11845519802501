@import "~antd/dist/antd.css";
html {
  scroll-behavior: smooth;
  font-family: Tahoma;
}

div#root {
  height: 100%;
}

/* div.ant-table-body {
  overflow-y: scroll;
  max-height: 100%;
  background-color: orange;
  height: 85%;
}

div.ant-table-body > table {
  table-layout: unset;
  height: -webkit-fill-available;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  float: left;
  width: auto;
  height: 31px;
  color: "white";
  margin: 16px 24px 16px 0;
  /* background: rgba(255, 255, 255, 0.3); */
}

.logoMobile {
  float: left;
  width: 100%;
  text-align: center;
  height: auto;
  color: 'white';
  padding: 8px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-table-tbody > tr > td {
  border-bottom: none;
}

.text-block {
  /* width: 500px; */
  font-family: monospace;
  /* font-family: serif; */
  float: left;
  text-align: justify;
}

div#root,
div#root > div {
  height: 100%;
}

.site-layout-content {
  background: #fff;
  /* padding: 16px; */
  height: 100%;
  min-height: 100%;
  /* overflow: auto; */
}

div.ant-spin-nested-loading {
  position: "inherit";
  background-color: "gray";
  height: "inherit";
}

div.site-layout-content > div.ant-spin-nested-loading,
div.site-layout-content > div.ant-spin-nested-loading > div {
  height: 100%;
  /* background-color: red; */
}

div.ant-spin-container {
  height: inherit;
}

#components-layout-demo-top .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}

tbody.ant-table-tbody > tr {
  min-width: 200px;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: white;
}

div.ant-row.ant-form-item {
  margin-bottom: 8px;
}

div.ant-picker-range-wrapper.ant-picker-date-range-wrapper {
  max-width: 90vw;
}

div.ant-picker-panel-container {
  overflow-x: auto;
}

div.ant-modal-body {
  max-height: 55vh;
  overflow-y: auto;
}

div.ant-image.imageRadius > img {
  border-radius: 10px;
}
